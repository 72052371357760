import { ThemeProvider } from '@mui/system'
import { RecoilRoot, useRecoilValue } from 'recoil'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import './scaffold.css'
import './index.css'
import { orgIdAtom } from './state'
import { theme } from './theme'

import { IntercomProvider } from 'react-use-intercom'
import FatalErrorBoundary from './components/FatalErrorBoundary/FatalErrorBoundary'
import FatalErrorPage from 'src/pages/FatalErrorPage'
const BaseApp = () => {
  const orgId = useRecoilValue<number | undefined>(orgIdAtom)

  const safeOrgId = orgId ? orgId : 0

  return (
    // eslint-disable-next-line
    //@ts-ignore
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <RedwoodApolloProvider
              useAuth={useAuth}
              graphQLClientConfig={{
                httpLinkConfig: {
                  headers: {
                    orgid: safeOrgId.toString(),
                  },
                },
              }}
            >
              <Routes />
            </RedwoodApolloProvider>
          </ThemeProvider>
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  )
}

const App = () => (
  <RecoilRoot>
    <IntercomProvider appId="dx62su88">
      <BaseApp />
    </IntercomProvider>
  </RecoilRoot>
)

export default App
