import { hydrateRoot, createRoot } from 'react-dom/client'

import * as Sentry from '@sentry/browser'

import App from './App'

// Set up Sentry error reporting
if (`${window.location}`.indexOf('http://localhost') !== 0) {
  Sentry.init({
    dsn: 'https://cfed2ef875a44ebf97d3c95495ff4233@o1400451.ingest.sentry.io/6729414',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const redwoodAppElement = document.getElementById('redwood-app')

if (redwoodAppElement?.children?.length ?? 0 > 0) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  hydrateRoot(redwoodAppElement!, <App />)
} else {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(redwoodAppElement!)
  root.render(<App />)
}
