import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    coolgrey: Palette['primary']
    textcolor: Palette['primary']
  }
  interface PaletteOptions {
    textcolor: PaletteOptions['primary']
    coolgrey: PaletteOptions['primary']
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    xs: true
    sm: true
    base: true
    lg: true
    xl: true
    '2xl': true
    '3xl': true
    '4xl': true
    '5xl': true
    '6xl': true
  }
}
const defaultTheme = createTheme()
const colors = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: '#FFF',
    },
    primary: {
      main: '#1A365D',
      light: '#475E7D',
      dark: '#122741',
      contrastText: '#F8F9FA',
    },
    secondary: {
      main: '#8FD9AB',
      light: '#C5F6D8',
      dark: '#67A17C',
      contrastText: '#F8F9FA',
    },
    error: {
      main: '#EC491F',
      light: '#FDA28B',
      dark: '#DF2D00',
      contrastText: '#F8F9FA',
    },
    info: {
      main: '#FDA28B',
      light: '#FBBCAF',
      dark: '#FDA28B',
      contrastText: '#F8F9FA',
    },
    textcolor: defaultTheme.palette.augmentColor({
      color: {
        main: '#222426',
        light: '#5F6A7A',
      },
    }),
    coolgrey: defaultTheme.palette.augmentColor({
      color: {
        main: '#F8F9FA',
        50: '#F8F9FA',
        100: '#F1F2F3',
        200: '#E1E3E5',
        300: '#C8CBD0',
        700: '#474E5B',
        800: '#222A36',
        900: '#161B25',
      },
    }),
  },
})
const components = createTheme(colors, {
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          borderColor: 'red',
          backgroundColor: 'white',
        },
        sizeSmall: {
          borderRadius: '5px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          color: colors.palette.primary.main,
          textColor: 'white',
          border: 'none',
          backgroundColor: 'unset !important',
          '&.Mui-selected': {
            opacity: '1',
            color: 'red',
            backgroundColor: 'unset !important',
          },
          '&:hover': {
            opacity: '0.8',
            color: 'red',
            backgrgoundColor: 'red',
          },
        },
      },
    },
  },
})

export const theme = createTheme(components, {
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.palette.primary.contrastText,
          cursor: 'pointer',
          textDecoration: 'none',
          fontWeight: 600,
          fontSize: '1rem',
          '&:hover': {
            color: colors.palette.secondary.light,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.palette.primary.main,
          borderBottomWidth: 'medium',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: colors.palette.secondary.main,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          '&.MuiDrawer-paper': {
            borderRadius: 'unset',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.palette.primary.main,
          fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [colors.breakpoints.down('sm')]: {
            paddingLeft: '2rem',
            paddingRight: '2rem',
          },
          [colors.breakpoints.up('sm')]: {
            paddingLeft: '3rem',
            paddingRight: '3rem',
          },
        },
      },
    },
    MuiStack: {
      defaultProps: {
        direction: 'row',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.palette.primary.main,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          fontSize: '0.9375rem',
          textTransform: 'unset',
          borderRadius: '20px',
          boxShadow: 'unset',
          '&:hover': {
            backgroundColor: colors.palette.primary.light,
            color: '#3c52b2',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: '100%',
          height: 'Hug',
          borderRadius: '16px',
          paddingTop: '20px',
          paddingBottom: '24px',
          paddingLeft: '24px',
          paddingRight: '24px',
          boxSizing: 'border-box',
        },
      },
    },
  },
  typography: {
    /*
     * Font weights:
     * 300 Light
     * 400 Regular
     * 600 Big
     * 700 Bold
     * 800 ExtraBold
     */
    xs: {
      fontSize: '.75rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.01em',
    },
    sm: {
      fontSize: '.875rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.01em',
    },
    base: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.02em',
    },
    lg: {
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.02em',
    },
    xl: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.01em',
    },
    '2xl': {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.01em',
    },
    '3xl': {
      fontSize: '1.875rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.01em',
    },
    '4xl': {
      fontSize: '2.25rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.01em',
    },
    '5xl': {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.01em',
    },
    '6xl': {
      fontSize: '4rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '-0.01em',
    },
    button: {},
    caption: {},
    overline: {},
  },
})
