import { atom } from 'recoil'

export const orgIdAtom = atom<number | undefined>({
  key: 'orgId',
  default: undefined,
  effects: [localStorageEffect<number | undefined>('org_id')],
})

export type PlantIdType = number | undefined

export const plantIdAtom = atom<PlantIdType>({
  key: 'plantId',
  default: undefined,
  effects: [localStorageEffect<PlantIdType>('plant_id')],
})

export const invoiceIdAtom = atom({
  key: 'invoiceId',
  default: undefined,
})

function localStorageEffect<T>(key: string) {
  return ({
    setSelf,
    onSet,
  }: {
    setSelf: (input: number | undefined) => void
    onSet: (inputs: (newValue: T, _: unknown, isReset: boolean) => void) => void
  }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      try {
        setSelf(JSON.parse(savedValue))
      } catch (e) {
        console.error(e)
      }
    }
    onSet((newValue: T, _: unknown, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }
}
